<template>
    <div
        class="select-none rounded-t-lg p-3 font-fira-code bg-gray-900 opacity-70 flex justify-between items-center shadow">
        <span class="text-white p-1 border border-b-0 border-gray-700 hover:opacity-80 rounded-md cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </span>
        <span class="text-white max-sm:hidden">guest@netosep.dev: ~</span>
        <ControlButtons />
    </div>
</template>

<script>
import ControlButtons from '@/components/Terminal/Header/ControlButtons';

export default {
    components: {
        ControlButtons,
    },
}
</script>