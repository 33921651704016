<template>
    <div class="about flex flex-col m-2">
        <span>-----------------------------------</span>
        <span>Hi! 🙋🏻‍♂️ - My name is Neto Sepulveda</span>
        <span>-----------------------------------</span>
        <span>👦🏻 I'm {{ age }} years old</span>
        <span>👨🏻‍💻 I'm a Software Developer</span>
        <span>📚 Graduated in Systems Analysis and Development at IFBaiano</span>
        <span>🖤 I like everything that involves technology and design</span>
        <span>-----------------------------------</span>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    setup() {
        const age = ref(null)
        const birthMonth = ref(2)
        const birthYear = ref(1998)

        function calculateAgeFromMonthYear(month, year) {
            const currentDate = new Date()
            const currentYear = currentDate.getFullYear()
            const currentMonth = currentDate.getMonth() + 1

            const yearsDifference = currentYear - year
            const monthsDifference = currentMonth - month

            let age = yearsDifference
            if (monthsDifference < 0) {
                age--
            }

            return age
        }

        age.value = calculateAgeFromMonthYear(birthMonth.value, birthYear.value)

        return {
            age,
        }
    }
}
</script>

<style scoped>
@media not all and (min-width: 640px) {
    .about {
        font-size: .8rem;
    }
}
</style>
