<template>
    <div class="m-10 max-lg:m-5 max-sm:m-0 border border-gray-600 rounded-lg shadow-lg h-window">
        <BaseHeader />
        <BaseBody />
    </div>
</template>

<script>
import BaseHeader from '@/components/Terminal/Header/BaseHeader.vue'
import BaseBody from '@/components/Terminal/Body/BaseBody.vue'

export default {
    components: {
        BaseHeader,
        BaseBody,
    }
}
</script>

<style scoped>
.h-window {
    height: calc(100vh - 5rem);
    max-height: calc(100vh - 5rem);
    width: calc(100vw - 5rem);
    max-width: calc(100vw - 5rem);
}

@media not all and (min-width: 1024px) {
    .h-window {
        height: calc(100vh - 2.5rem);
        max-height: calc(100vh - 2.5rem);
        width: calc(100vw - 2.5rem);
        max-width: calc(100vw - 2.5rem);
    }
}

@media not all and (min-width: 640px) {
    .h-window {
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        max-width: 100vw;
    }
}
</style>