<template>
    <div ref="terminalBody"
        class="text-white bg-gray-950 opacity-80 font-fira-code p-3 max-sm:p-1 rounded-b-lg terminal-body overflow-auto">
        <AsciiText />
        <div v-for="(line, index) in lines" :key="index" class="w-full">
            <LabelServer />
            <div v-if="index === lines.length - 1" class="w-full">
                <form @submit.stop.prevent="submitForm" class="flex">
                    <span class="px-3">»</span>
                    <input ref="terminalInput" type="text"
                        class="text-white bg-transparent focus:outline-none w-full max-sm:p-0" v-model="currentInput"
                        @keyup.up.prevent="previousCommand" @keyup.down.prevent="nextCommand"
                        @keydown.ctrl.l.prevent="clearTerminal">
                </form>
            </div>
            <div v-else-if="line.type === 'text' && line.content" class="p-2 line break-words" v-html="line.content">
            </div>
            <component v-else :is="line.content"></component>
        </div>
    </div>
</template>

<script>
import AsciiText from './AsciiText.vue'
import ErrorMessage from '@/components/Terminal/Commands/ErrorMessage.vue'
import LabelServer from '@/components/Terminal/Body/LabelServer.vue'
import { nextTick, onBeforeUnmount, onMounted, ref, markRaw, h } from 'vue'
import commands from '@/components/Terminal/commands.js'

export default {
    components: {
        AsciiText,
        ErrorMessage,
        LabelServer,
    },
    setup() {
        const terminalInput = ref(null)
        const terminalBody = ref(null)
        const currentInput = ref(null)
        const lines = ref([{ type: 'text', content: null }])
        const history = ref([])
        const index = ref(null)

        onMounted(() => {
            setFocus()
            terminalBody.value.addEventListener('click', setFocus)
        })

        onBeforeUnmount(() => {
            terminalBody.value?.removeEventListener('click', setFocus)
        })

        function setFocus() {
            terminalInput.value[0].focus()
        }

        function scrollToEnd() {
            terminalBody.value.scrollTop = terminalBody.value.scrollHeight
        }

        function refresh() {
            currentInput.value = ''
            nextTick(() => {
                scrollToEnd()
                setFocus()
            })
        }

        function spliceLineCommand(value) {
            lines.value.splice(lines.value.length - 1, 0, value)
        }

        function pushCommand(command) {
            history.value.push(command)
        }

        const submitForm = () => {
            const content = currentInput.value?.trim()
            const inputCommand = content?.split(' ')[0]
            const params = content?.split(' ').slice(1).join(' ')

            if (!commands.map((c) => c.name).includes(inputCommand?.toLowerCase())) {
                if (!inputCommand || inputCommand === '') {
                    spliceLineCommand({ type: 'text', content: null })
                } else {
                    pushCommand(inputCommand)
                    spliceLineCommand({
                        type: 'component', content: markRaw({
                            render() {
                                return h(ErrorMessage, { command: inputCommand })
                            }
                        })
                    })
                }
            } else {
                pushCommand(inputCommand)
                commands.forEach((command) => {
                    if (command.name === inputCommand?.toLowerCase()) {
                        const result = command.handler({ history: history.value, params: params })
                        if (typeof result === 'string') {
                            return spliceLineCommand({ type: 'text', content: result })
                        }

                        if (typeof result === 'function') {
                            return result({ history: history.value, params: params === '' ? null : params })
                        }

                        if (Array.isArray(result)) {
                            return lines.value = result
                        }

                        spliceLineCommand({ type: 'component', content: markRaw(result) })
                    }
                })
            }
            refresh()
        }

        const clearTerminal = () => {
            lines.value = ['']
            refresh()
        }

        const previousCommand = () => {
            if (history.value.length === 0) {
                return
            }

            if (!index.value) {
                index.value = history.value.length
            }

            if (history.value.length > 0) {
                currentInput.value = history.value[index.value-- < 0 ? history.value.length : index.value]
            }
        }

        const nextCommand = () => {
            if (history.value.length === 0) {
                return
            }

            if (index.value >= history.value.length) {
                index.value = -1
            }

            if (history.value.length > 0) {
                currentInput.value = history.value[index.value >= history.value.length ? 0 : index.value++]
            }
        }

        return {
            terminalInput,
            terminalBody,
            currentInput,
            lines,
            submitForm,
            clearTerminal,
            previousCommand,
            nextCommand,
        }
    }
}
</script>

<style scoped>
.terminal-body {
    height: calc(100% - 3.1rem);
}

.terminal-body::-webkit-scrollbar {
    width: .7rem;
    margin-right: 10px;
    background-color: transparent;
    border-radius: .5rem;
}

.terminal-body::-webkit-scrollbar-track {
    background: transparent;
    margin: .5rem;
}

.terminal-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 145, 189, 0.5);
    border-radius: .5rem;
    border: .2rem solid rgb(3 7 18 / var(--tw-bg-opacity));
}

@media not all and (min-width: 640px) {
    .terminal-body {
        font-size: .95rem;
    }
}
</style>