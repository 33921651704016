<template>
    <div class="ms-2">
        <span>{{ command }}</span>:
        <span class="text-red-400">command not found!</span>
    </div>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: {
        command: {
            type: String,
            required: true
        }
    }
}
</script>