import AboutCommand from '@/components/Terminal/Commands/AboutCommand.vue'
import AsciiText from '@/components/Terminal/Body/AsciiText.vue'
import HelpCommand from '@/components/Terminal/Commands/HelpCommand.vue'
import ProjectsCommand from '@/components/Terminal/Commands/ProjectsCommand.vue'
import SocialsCommand from '@/components/Terminal/Commands/SocialsCommand.vue'

export default [
    {
        name: 'about',
        desc: 'about me',
        tabs: 5,
        handler: () => AboutCommand
    },
    {
        name: 'clear',
        desc: 'clear the terminal',
        tabs: 5,
        handler: () => ['']
    },
    {
        name: 'echo',
        desc: 'print out anything',
        tabs: 6,
        handler: (data) => `${data.params ? data.params : ''}`
    },
    {
        name: 'email',
        desc: 'send me a email',
        tabs: 5,
        handler: () => 'contato@netosep.dev'
    },
    {
        name: 'help',
        desc: 'check available commands',
        tabs: 6,
        handler: () => HelpCommand
    },
    {
        name: 'history',
        desc: 'show the terminal command history',
        tabs: 3,
        handler: (data) => data.history.join('<br>')
    },
    {
        name: 'projects',
        desc: 'view my projects',
        tabs: 2,
        handler: () => ProjectsCommand
    },
    {
        name: 'pwd',
        desc: 'print current directory',
        tabs: 7,
        handler: () => '/home/neto'
    },
    {
        name: 'socials',
        desc: 'check out my socials',
        tabs: 3,
        handler: () => SocialsCommand
    },
    {
        name: 'sudo',
        desc: 'run a command with root privilege',
        tabs: 6,
        handler: (data) => data.params ? `${data.params}: Permission denied` : 'Permission denied'
    },
    {
        name: 'welcome',
        desc: 'display welcome message',
        tabs: 3,
        handler: () => AsciiText
    },
    {
        name: 'whoami',
        desc: 'about current user',
        tabs: 4,
        handler: () => 'guest'
    },
]
