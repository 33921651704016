<template>
    <div class="socials m-2">
        <div v-for="(social, index) in socials" :key="index">
            <span class="me-2">=> {{ social.name }}:</span>
            <a :href="social.url" class="inline-block text-orange-400 underline" target="_blank">
                {{ social.user }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            socials: [
                {
                    name: 'Linkedin',
                    user: 'Neto Sepulveda',
                    url: 'https://linkedin.com/in/netosepulveda'
                },
                {
                    name: 'Github',
                    user: 'netosep',
                    url: 'https://github.com/netosep'
                },
                {
                    name: 'Email',
                    user: 'contato@netosep.dev',
                    url: 'mailto:contato@netosep.dev'
                },
                {
                    name: 'Instagram',
                    user: 'netosepulveda_',
                    url: 'https://instagram.com/netosepulveda_'
                },
                {
                    name: 'X (Twitter)',
                    user: 'net0xy',
                    url: 'https://x.com/net0xy'
                },
                {
                    name: 'Steam',
                    user: 'ntx </>',
                    url: 'https://steamcommunity.com/id/net0x'
                },
                {
                    name: 'Discord',
                    user: 'net0x',
                    url: 'https://discord.com/channels/@me'
                },
            ]
        }
    }
}
</script>

<style>
@media not all and (min-width: 640px) {
    .socials {
        font-size: .9rem;
    }
}
</style>