<template>
    <div class="text-white flex items-center">
        <div
            class="minimize p-1 hover:opacity-80 border border-b-0 rounded-md border-gray-900 hover:border-gray-700 cursor-pointer delay-75">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 17h14" />
            </svg>
        </div>
        <div
            class="window mx-4 p-1 hover:opacity-80 border border-b-0 rounded-md border-gray-900 hover:border-gray-700 cursor-pointer delay-75">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z" />
            </svg>
        </div>
        <div
            class="close rounded-full bg-red-800 p-1 hover:bg-opacity-80 border border-gray-900 hover:border-gray-700 cursor-pointer delay-75">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
</template>