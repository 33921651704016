<template>
    <div class="ascii text-sm m-3 max-lg:m-0 inline-block">
        <span class="font-mono">
            → Welcome to my terminal portifolio 👨🏻‍💻
        </span>
        <pre>
 _   _      _          _____                  _               _       
| \ | |    | |        /  ___|                | |             | |      
|  \| | ___| |_ ___   \ `--.  ___ _ __  _   _| |_   _____  __| | __ _ 
| . ` |/ _ \ __/ _ \   `--. \/ _ \ '_ \| | | | \ \ / / _ \/ _` |/ _` |
| |\  |  __/ || (_) | /\__/ /  __/ |_) | |_| | |\ V /  __/ (_| | (_| |
\_| \_/\___|\__\___/  \____/ \___| .__/ \__,_|_| \_/ \___|\__,_|\__,_|
                                 | |                                  
       • Software Developer      |_|     • <a href="https://github.com/netosep" class="hover:underline" target="_blank">github.com/netosep</a>                             
        </pre>
        <span class="animate-pulse">
            ✨ For a list of available commands, type
            `<span class="text-orange-500">help</span>` ✨
        </span>
    </div>
</template>

<style scoped>
@media not all and (min-width: 640px) {
    .ascii pre {
        font-size: 0.65rem;
        line-height: 0.75rem;
    }

    .ascii .font-mono {
        font-size: .95rem;
        line-height: 1rem;
    }
}

@media not all and (min-width: 1024px) {
    .ascii {
        font-size: 0.75rem;
        line-height: 1rem;
    }
}
</style>