<template>
    <div class="commands p-2">
        <span class="flex" v-for="(command, index) in commands" :key="index">
            <span class="text-green-200">{{ command.name }}</span>
            <span>{{ '&nbsp;'.repeat(command.tabs) }}</span>
            <span>- {{ command.desc }}</span>
        </span>
        <div class="mt-3">
            <span class="flex">
                <span class="text-cyan-200">Ctrl+l</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>=> clear the terminal</span>
            </span>
            <span class="flex">
                <span class="text-cyan-200">Arrow Up</span>
                <span>&nbsp;&nbsp;</span>
                <span>=> navigate to used commands</span>
            </span>
        </div>
    </div>
</template>

<script>
import commands from '@/components/Terminal/commands.js'

export default {
    setup() {
        return {
            commands
        }
    }
}
</script>

<style scoped>
@media not all and (min-width: 640px) {
    .commands {
        font-size: .8rem;
    }
}
</style>