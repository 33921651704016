<template>
    <div class="flex label">
        <span class="me-2 max-sm:me-1">🔥</span>
        <span class="text-yellow-500">guest</span>
        <span class="text-blue-100">@</span>
        <span class="text-cyan-300">netosep.dev</span>
        <span class="text-whit">:</span>
        <span class="text-blue-500">~</span>
        <span class="text-white">$</span>
    </div>
</template>