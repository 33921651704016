<template>
    <div class="projects m-2">
        <div v-for="(project, index) in projects" :key="index" class="flex flex-col my-2">
            <a :href="project.url" target="_blank" class="w-fit hover:underline">
                <strong>{{ index + 1 }}. {{ project.name }}</strong>
            </a>
            <span>{{ project.description }}</span>
        </div>
        <div class="text-yellow-200 mt-2">
            <span>
                To see more projects visit my
                <a href="https://github.com/netosep?tab=repositories" class="animate-pulse underline" target="_blank">
                    github page
                </a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            projects: [
                {
                    name: 'textural-triangle-canvas',
                    description: 'Textural Triangle for soil classification developed with HTML Canvas',
                    url: 'https://github.com/netosep/textural-triangle-canvas'
                },
                {
                    name: 'vue-todo',
                    description: 'TodoApp made with Vue3 + Composition API using json-server as backend',
                    url: 'https://github.com/netosep/vue-todo'
                },
                {
                    name: 'github-search',
                    description: 'Web page for consulting and listing repositories using the Github API',
                    url: 'https://github.com/netosep/github-search'
                },
                {
                    name: 'firebase-gallery',
                    description: 'A simple gallery application using FirebaseStorage to present a college project',
                    url: 'https://github.com/netosep/firebase-gallery'
                },
                {
                    name: 'StudayApp',
                    description: 'A small platform for student and teacher to meet for tutoring or private lessons',
                    url: 'https://github.com/netosep/StudayApp'
                },
                {
                    name: 'limbo-bot',
                    description: 'A simple discord bot developed in Node.js + MongoDB',
                    url: 'https://github.com/netosep/limbo-bot'
                },
            ]
        }
    }
}
</script>

<style>
@media not all and (min-width: 640px) {
    .projects {
        font-size: .85rem;
    }
}
</style>